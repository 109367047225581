*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

.container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  cursor: crosshair;
}

.bird {
  background-image: url(../img/bird-cells.svg);
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
  animation-name: fly-cycle;
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;
}

.bird--one {
  animation-duration: 2.4s;
  animation-delay: -0.5s;
}

.bird:hover,
.bird:focus {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.bird--two {
  animation-duration: 2.3s;
  animation-delay: -0.75s;
}

.bird--three {
  animation-duration: 2.3s;
  animation-delay: -0.25s;
}

.bird--four {
  animation-duration: 2.2s;
  animation-delay: -0.5s;
}

.bird--five {
  animation-duration: 2.4s;
  animation-delay: -0.5s;
}
.bird--six {
  animation-duration: 2.4s;
  animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 20%;
  transform: translateY(8vh) scale(0.15);
  left: -50px;
  animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  z-index: 3;
}

.bird-container-one {
  position: absolute;
  top: 20%;
  transform: translateY(12vh) scale(0.1);
  left: -50px;
  animation-name: fly-right-two;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  z-index: 3;
}

.bird-container--one {
  animation-duration: 38s;
  animation-delay: 0s;
}

.bird-container--two {
  animation-duration: 44s;
  animation-delay: 3s;
}

.bird-container--three {
  animation-duration: 42s;
  animation-delay: 6.5s;
}

.bird-container--four {
  animation-duration: 38s;
  animation-delay: 8.25s;
}

.bird-container--five {
  animation-duration: 44s;
  animation-delay: 10.25s;
}

.bird-container--six {
  animation-duration: 46s;
  animation-delay: 13s;
}

.stars {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: url(../img/stars-sm.webp) repeat-x top / 30%,
              linear-gradient(0deg, rgba(25, 110, 183, 0.91) 30%, rgba(0, 0, 70, 0.91));
  z-index: 1;
}


@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-right-one {
  from {
    left: -50px;
  }
  to {
    left: 100%;
  }
}

@keyframes fly-right-two {
  from {
    left: -50px;
  }
  to {
    left: 100%;
  }
}

#forest {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url(../img/forest_sm.webp);
  opacity: 0.75;
  height: 50vh;
  max-height: 622px;
  background-size: cover;
}

#horse {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 25vh;
  max-height: 311px;
}
