*, :before, :after {
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

.container {
  cursor: crosshair;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.bird {
  width: 88px;
  height: 125px;
  will-change: background-position;
  background-image: url("bird-cells.07beb9f7.svg");
  background-size: auto 100%;
  animation-name: fly-cycle;
  animation-timing-function: steps(10, end);
  animation-iteration-count: infinite;
}

.bird--one {
  animation-duration: 2.4s;
  animation-delay: -.5s;
}

.bird:hover, .bird:focus {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.bird--two {
  animation-duration: 2.3s;
  animation-delay: -.75s;
}

.bird--three {
  animation-duration: 2.3s;
  animation-delay: -.25s;
}

.bird--four {
  animation-duration: 2.2s;
  animation-delay: -.5s;
}

.bird--five, .bird--six {
  animation-duration: 2.4s;
  animation-delay: -.5s;
}

.bird-container {
  z-index: 3;
  animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: 20%;
  left: -50px;
  transform: translateY(8vh)scale(.15);
}

.bird-container-one {
  z-index: 3;
  animation-name: fly-right-two;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: 20%;
  left: -50px;
  transform: translateY(12vh)scale(.1);
}

.bird-container--one {
  animation-duration: 38s;
  animation-delay: 0s;
}

.bird-container--two {
  animation-duration: 44s;
  animation-delay: 3s;
}

.bird-container--three {
  animation-duration: 42s;
  animation-delay: 6.5s;
}

.bird-container--four {
  animation-duration: 38s;
  animation-delay: 8.25s;
}

.bird-container--five {
  animation-duration: 44s;
  animation-delay: 10.25s;
}

.bird-container--six {
  animation-duration: 46s;
  animation-delay: 13s;
}

.stars {
  z-index: 1;
  background: url("stars-sm.ff11df9c.webp") top / 30% repeat-x, linear-gradient(0deg, #196eb7e8 30%, #000046e8);
  position: absolute;
  inset: 0;
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-right-one {
  from {
    left: -50px;
  }

  to {
    left: 100%;
  }
}

@keyframes fly-right-two {
  from {
    left: -50px;
  }

  to {
    left: 100%;
  }
}

#forest {
  opacity: .75;
  height: 50vh;
  max-height: 622px;
  background-image: url("forest_sm.4cfafa55.webp");
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#horse {
  height: 25vh;
  max-height: 311px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/*# sourceMappingURL=index.b89f2ab8.css.map */
